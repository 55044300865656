import React from 'react';

const Alert = ({ children }) => {
  return (
    <div className="w-full bg-red-50 text-red-800 px-4 py-2 rounded">
      {children}
    </div>
  );
};

export default Alert;
