import React from 'react';
import LoginLayout from 'src/components/layouts/login';
import Login from 'src/components/login';
import SEO from 'src/components/seo';
import Card from 'src/components/design-system/card';

const LoginPage = () => {
  return (
    <LoginLayout>
      <SEO title="Connexion" />
      <Card className="max-w-2xl">
        <Login />
      </Card>
    </LoginLayout>
  );
};

export default LoginPage;
