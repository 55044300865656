import React, { useState } from 'react';
import { useAuth } from 'src/components/authentication';

import * as DesignSystem from 'src/components/design-system';
import Alert from 'src/components/design-system/alert';
import Logo from 'src/components/logo';
import ActionButton from 'src/components/design-system/buttons/action';

const Login = () => {
  const { login, loginState } = useAuth();
  const { error } = loginState;
  const [credentials, setCredentials] = useState({ email: '', password: '' });

  const setEmail = (e) => {
    const email = e.target.value;
    setCredentials({ ...credentials, email });
  };

  const setPassword = (e) => {
    const password = e.target.value;
    setCredentials({ ...credentials, password });
  };

  const submit = (e) => {
    e.preventDefault();
    login(credentials);
  };

  return (
    <div>
      <div className="">
        <Logo className="m-auto h-36" />
        <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900 leading-9">
          Connectez-vous
        </h2>
        <p className="mt-2 text-sm text-gray-600 leading-5 max-w"></p>
      </div>

      {error && <Alert>Adresse email ou mot de passe incorrect</Alert>}

      <div className="">
        <div className="mt-4">
          <form action="#" method="POST" onSubmit={submit}>
            <div>
              <label
                for="email"
                className="block text-sm font-medium text-gray-700 leading-5"
              >
                Adresse email
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  type="email"
                  onChange={setEmail}
                  required
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 appearance-none rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            <div className="mt-6">
              <label
                for="password"
                className="block text-sm font-medium text-gray-700 leading-5"
              >
                Mot de passe
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  type="password"
                  onChange={setPassword}
                  required
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 appearance-none rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            <div className="flex items-center justify-between mt-6">
              <div className="flex items-center"></div>
              <DesignSystem.Link to="/mot-de-passe-oublie">
                Mot de passe oublié ?
              </DesignSystem.Link>
            </div>

            <ActionButton
              className="w-full mt-6 justify-center"
              type="submit"
              isLoading={
                loginState &&
                loginState.status &&
                loginState.status === 'loading'
              }
            >
              Connexion
            </ActionButton>

            <ActionButton
              className="w-full mt-6 justify-center"
              color="light"
              to="/activate"
            >
              Activer votre compte
            </ActionButton>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
